@font-face {
  font-family: Raleway;
  src:
    local('Raleway'),
    url('../fonts/Raleway-VariableFont_wght.woff2') format('woff2 supports variations'),
    url('../fonts/Raleway-VariableFont_wght.woff2') format('woff2-variations'),
    url('../fonts/Raleway-VariableFont_wght.ttf') format('truetype');
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

//@import "https://fonts.googleapis.com/css2?family=Raleway:wght@100..900&display=swap";
