$icons: (
  aboAuswahl: "\ea02",
  aboAward: "\ea03",
  aboBook: "\ea04",
  aboComfortable: "\ea05",
  aboDelivery: "\ea06",
  aboDiscovered: "\ea07",
  aboEuro: "\ea08",
  aboExclusiv: "\ea09",
  aboFlexibel: "\ea0a",
  aboGeniessen: "\ea0b",
  aboGeschenk: "\ea0c",
  aboLine: "\ea0d",
  aboNoRisk: "\ea0e",
  aboQuality: "\ea0f",
  aboRecommended: "\ea10",
  aboSavings: "\ea11",
  aboThanks: "\ea12",
  aboWahl: "\ea13",
  arrowDown: "\ea14",
  arrowDownBold: "\ea15",
  arrowLeft: "\ea16",
  arrowLeftBold: "\ea17",
  arrowLineRight: "\ea18",
  arrowLineUp: "\ea19",
  arrowRight: "\ea1a",
  arrowRightBold: "\ea1b",
  arrowRightThin: "\ea1c",
  arrowUp: "\ea1d",
  arrowUpBold: "\ea1e",
  benefitsBottle: "\ea1f",
  benefitsChecklist: "\ea20",
  benefitsDhl: "\ea21",
  benefitsGaranty: "\ea22",
  benefitsPaket: "\ea23",
  benefitsService: "\ea24",
  benefitsThumb: "\ea25",
  benefitsWineglas: "\ea26",
  calendar: "\ea27",
  camera: "\ea28",
  cart: "\ea29",
  category: "\ea2a",
  certificate: "\ea2b",
  check: "\ea2c",
  checkSmall: "\ea2d",
  checked: "\ea2e",
  checklist: "\ea2f",
  close: "\ea30",
  cloud: "\ea31",
  comment: "\ea32",
  confirmationArrow: "\ea33",
  confirmationBox: "\ea34",
  confirmationHome: "\ea35",
  confirmationMail: "\ea36",
  confirmationTracking: "\ea37",
  confirmationVersand: "\ea38",
  countdown: "\ea39",
  delete: "\ea3a",
  delivery: "\ea3b",
  documentCheck: "\ea3c",
  download: "\ea3d",
  edit: "\ea3e",
  email: "\ea3f",
  eurCircle: "\ea40",
  exitFullscreenIcon: "\ea41",
  expand: "\ea42",
  eye: "\ea43",
  facebook: "\ea44",
  facebookInverted: "\ea45",
  filter: "\ea46",
  freeShippingIcon: "\ea47",
  fwAuswaehlen: "\ea48",
  fwEinkaufen: "\ea49",
  fwEmpfehlen: "\ea4a",
  fwPraemieErhalten: "\ea4b",
  giftcard: "\ea4c",
  grape: "\ea4d",
  grapes: "\ea4e",
  haweskoOwn: "\ea4f",
  haweskoPartner: "\ea50",
  headset: "\ea51",
  heart: "\ea52",
  heartFilled: "\ea53",
  heartUnfilled: "\ea54",
  home: "\ea55",
  info: "\ea56",
  infoPopup: "\ea57",
  instagram: "\ea58",
  letter: "\ea59",
  linkLetter: "\ea5a",
  loading: "\ea5b",
  logoClaim: "\ea5c",
  mail: "\ea5d",
  mailOpen: "\ea5e",
  medalIcon: "\ea5f",
  mobileMenu: "\ea60",
  packet: "\ea61",
  phone: "\ea62",
  plus: "\ea63",
  present: "\ea64",
  productAnbaugebiet: "\ea65",
  productBox: "\ea66",
  productLagerfaehigkeit: "\ea67",
  productRebsorten: "\ea68",
  productTrinktemperatur: "\ea69",
  productReturn: "\ea6a",
  quality: "\ea6b",
  redwine: "\ea6c",
  regions: "\ea6d",
  reload: "\ea6e",
  return: "\ea6f",
  rosewine: "\ea70",
  search: "\ea71",
  searchNativeMenu: "\ea72",
  service: "\ea73",
  shipmentIcon: "\ea74",
  shipping: "\ea75",
  similarSearch: "\ea76",
  star: "\ea77",
  statusDelayed: "\ea78",
  statusDelivery: "\ea79",
  statusHome: "\ea7a",
  statusPackage: "\ea7b",
  statusReceived: "\ea7c",
  talk: "\ea7d",
  taste: "\ea7e",
  thumbDown: "\ea7f",
  thumbUp: "\ea80",
  thumbUpFill: "\ea81",
  time: "\ea82",
  trash: "\ea83",
  twitter: "\ea84",
  user: "\ea85",
  userMailCheck: "\ea86",
  varities: "\ea87",
  vcard: "\ea88",
  video: "\ea89",
  voucherFive: "\ea8a",
  whatsapp: "\ea8b",
  winemaker: "\ea8c",
  worldMap: "\ea8d",
  zoomIn: "\ea8e",

);