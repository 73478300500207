.html-block {
  $root: &;
  color: $htmlBlockTextColor;
  line-height: $htmlBlockTextLineHeight;

  @include fontSize($htmlBlockTextFontSize);

  &--with-spacing {
    width: 100%;
    max-width: $maxWidthOuter;
    padding-left: 10px;
    padding-right: 10px;
    margin: 20px auto;

    @media (min-width: $screen-mobile-large) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media (min-width: $screen-tablet-portrait) {
      padding-left: 20px;
      padding-right: 20px;
      margin: 40px auto;
    }
  }

  &--overflow {
    overflow: auto;
  }

  &--spacing-top {
    padding-top: 30px;

    @media (min-width: $screen-tablet-portrait) {
      padding-top: 40px;
    }
  }

  &--spacing-bottom {
    padding-bottom: 30px;

    @media (min-width: $screen-tablet-portrait) {
      padding-bottom: 40px;
    }
  }

  &--spacing-top-bottom {
    padding-top: 30px;
    padding-bottom: 30px;

    @media (min-width: $screen-tablet-portrait) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  h1,
  h2,
  h3 {
    margin: 24px 0 16px;
    line-height: 1.1;

    &:first-child {
      margin-top: 0;
    }

    &.headline--main-line {
      @include fontSize($headlineMainLineFontSize);
      font-weight: $headlineMainLineFontWeight;
    }
  }

  h4,
  h5,
  h6 {
    @include fontSize($baseFontSize);
    font-weight: $headlineH4FontWeight;
    margin: 20px 0 10px;
    line-height: $htmlBlockTextLineHeight;

    &.headline--main-line {
      @include fontSize($headlineMainLineFontSize);
      font-weight: $headlineMainLineFontWeight;
    }
  }

  h1 {
    @include fontSize($headlineH1FontSize);
    line-height: 32px;
    font-weight: $headlineH1FontWeight;
  }

  h2 {
    @include fontSize($headlineH2FontSize);
    font-weight: $headlineH2FontWeight;
  }

  h3 {
    @include fontSize($headlineH3FontSize);
    font-weight: $headlineH3FontWeight;
  }

  h4 {
    @include fontSize($headlineH4FontSize);
    font-weight: $headlineH4FontWeight;
  }

  p {
    color: $htmlBlockTextColor;
    margin: 10px 0;
    padding: 0;
    line-height: 22px;
    font-size: 16px;
  }

  strong,
  b {
    margin: 0;
    color: $htmlBlockTextColor;
    font-weight: $htmlBlockTextFontWeight;
  }

  blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    line-height: $htmlBlockTextLineHeight;
    font-size: $htmlBlockTextFontSizeMedium;
    border-left: 5px solid $htmlBlockTableColor;
  }

  img {
    max-width: 100%;
  }

  ul,
  ol {
    margin: 10px 0;
    padding: 0 0 0 30px;
  }

  table {
    border-color: $htmlBlockTableColor;
    hyphens: auto; // stylelint-disable-line plugin/no-unsupported-browser-features
  }

  th {
    padding: 5px 10px;
  }

  td {
    padding: 10px;
  }

  li {
    color: $htmlBlockTextColor;
  }

  a:not(.no-link) {
    color: $htmlBlockTextColor;
    text-decoration: underline;

    &:hover,
    &:visited {
      color: $htmlBlockTextColor;
      text-decoration: underline;
    }
  }

  &--text {
    display: block;
    font-size: $fontSizeFont;
    color: $htmlBlockTextColor;
    margin: 10px 0 0;
    padding: 0;
    line-height: $htmlBlockTextLineHeight;
  }

  &--no-padding {
    padding: 0 !important;
  }

  &--no-margin {
    margin: 0 !important;
  }

  &--no-margin-top {
    margin-top: 0 !important;
  }

  &--no-margin-bottom {
    margin-bottom: 0 !important;
  }

  &__content-section {
    margin: 15px 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 30px 0;
      flex-direction: row;
    }
  }

  &__content-row--big {
    margin: 30px 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 40px 0;

      #{$root}__content-col {
        padding: 0 20px;
      }
    }
  }

  &__content-row--teaser {
    @media (min-width: $screen-tablet-portrait) {
      #{$root}__content-col {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      img {
        max-height: 100%;
        width: 100% !important;
        display: block;
      }

      .image-element {
        display: inline;
        padding: 0;
      }
    }
  }

  &__content-row {
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-tablet-portrait) {
      flex-direction: row;
    }

    img {
      width: 100%;
    }
  }

  &__content-image {
    padding: 0 0 10px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0;
    }

    > a {
      width: 100%;
    }

    #{$root}__content-row--teaser & {
      padding: 0;
    }
  }

  &__content-col {
    padding: 0 0 10px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 10px;
      flex-grow: 1;
      flex-basis: 0;

      & > *:last-child {
        margin-bottom: 0;
      }
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &--full {
      width: 100%;
    }

    &--padding {
      padding: 20px;
    }

    &--padding-big {
      padding: 20px;

      @media (min-width: $screen-tablet-portrait) {
        padding: 40px;
      }
    }

    &--text-center {
      text-align: center;
    }

    &--grow-1 {
      flex-grow: 1;
    }
  }

  &__content-buttons {
    .button {
      vertical-align: top;
    }

    .button:first-child {
      margin: 0 0 20px;

      @media (min-width: $screen-mobile-landscape) {
        margin: 0 20px 20px 0;
      }
    }
  }

  &--bg-color1 {
    background: $gridBackgroundColor1;
  }

  &--bg-color2 {
    background: $gridBackgroundColor2;
  }

  &--bg-color3 {
    background: $gridBackgroundColor3;
  }

  &--bg-color4 {
    background: $gridBackgroundColor4;
  }

  &--bg-color5 {
    background: $gridBackgroundColor5;
  }

  &--bg-secondary {
    background: $brandSecondaryColor;
    color: $whiteColor !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      color: $whiteColor !important;
    }
  }

  &--bg-tertiary {
    background: $gridBackgroundColor4;
    color: $whiteColor !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      color: $whiteColor !important;
    }
  }

  .lightbox & {
    > *:first-child {
      margin-top: 0;
    }
  }

  .ul {
    &--gold {
      list-style: none;

      li::before {
        content: "\2022";
        color: $goldColor;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        transform: scale(1.8);
      }
    }
  }

  &__block--border {
    margin: 10px 0;
    padding: 20px;
    border: 1px solid $grayMediumColor;

    @media (min-width: $screen-tablet-portrait) {
      margin: 20px 0;
      padding: 40px;
    }
  }

  &__block--white {
    margin: 10px 0;
    padding: 20px;
    background: $whiteColor !important;
    color: $htmlBlockTextColor !important;

    @media (min-width: $screen-tablet-portrait) {
      margin: 20px 0;
      padding: 30px;
    }
  }

  &__block--white,
  &__block--brown,
  &__block--border {
    &:first-child {
      margin-top: 0;
    }

    &:last-child,
    p:last-child {
      margin-bottom: 0;
    }
  }

  .float-left {
    float: left;
    margin: 8px 8px 8px 0;
  }

  .float-right {
    float: right;
    margin: 8px 0 8px 8px;
  }
}
